import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"


const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 4" />  
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 4</h1>
      <p>Endelig fredag!</p>
      <p>Du er så flink til å synge, mamma! Takk for at du synger så fint til meg hver dag.</p>
      <p>Jeg har latt meg inspirere av sangen din, og litt av pappas pianospill av julesanger for tiden, så jeg fikk lyst til å komponere min første sang!</p>
      <p>Den dedikeres til fine mamman min, og heter "Jeg er så glad hver fredagskveld" med melodi fra julesangen med nesten lik tittel. Håper du liker den:</p>
      <p style={{textAlign: 'center'}}>
        ♫ Jeg er så glad hver fredagskveld<br/>
        for da kan jeg slappe av.<br/>
        Med beina hevet høyt til værs<br/>
        som om jeg gikk på NAV ♫</p>
      <p style={{textAlign: 'center'}}>
        ♫ Det sies at oss litjeners<br/>
        skal sove fra klokka sju.<br/>
        Og dette går jo jammen bra<br/>
        ja takk, mamma, fine du ♫</p>

      <p style={{textAlign: 'center'}}>
        ♫ Når midt på natta jeg våkner opp<br/>
        så vipps så er du jo der.<br/>
        Med varme klemmer og masse melk<br/>
        og holder meg så kjær ♫</p>

      <p style={{textAlign: 'center'}}>
        ♫ Til slutt så må jeg få takke deg,<br/>
        for meg du er medisin.<br/>
        Du sørger for at jeg har det best,<br/>
        jeg elsker deg mamman min. ♫</p>

      <p>Stor helgeklem fra Litjen</p>
      <p>PS. i dag spanderer pappa sushi, så du kan spare lasagnen til i morgen!</p>
    </div>
  </Layout>
)

export default LitjenLuke